import React from "react"

//components
import Layout from "../components/Layout"
import Section from "../components/Section"

const IndexNl = () => {
  const langSelect = [
    { locale: "fr", path: "" },
    { locale: "nl", path: "" },
  ]
  const langCurrent = "nl"

  return (
    <Layout langSelect={langSelect} langCurrent={langCurrent}>
      <Section className="main">Hello from NL</Section>
    </Layout>
  )
}

export default IndexNl
